var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',[(!_vm.isActiveSearch)?_c('div',[_c('DataDiscoveryHeader'),_c('br')],1):_vm._e(),_c('SearchBar',{attrs:{"initialFilters":_vm.searchInput.filters || {},"initialSearch":_vm.searchInput.searchText || ''},on:{"onSearchInputChange":_vm.handleSearchInputChange}}),(_vm.searching)?_c('div',{attrs:{"align":"center"}},[_c('img',{attrs:{"src":"spinner.svg","alt":"loading"}}),_c('br'),_vm._v(" Searching... ")]):(_vm.searchError)?_c('error-popup',{attrs:{"errorType":"snackbar-danger","message":_vm.searchError,"delay":6000,"onHide":_vm.clearSearchError}}):_vm._e(),(!_vm.isActiveSearch)?_c('div',[_c('br'),_c('br'),_c('div',{staticClass:"row"},[_c('div',{staticClass:"col-md-6"},[_c('RecenTablesSection')],1),_c('div',{staticClass:"col-md-6"},[_c('PopularTagsSection',{on:{"onTagClick":(e) => {
              _vm.handleSearchInputChange({
                filters: { tag: [e.tag] },
                searchText: '',
                trigger: true
              });
            }}})],1)]),_c('br')]):_vm._e(),(_vm.isActiveSearch && !_vm.searching && _vm.searchResult)?_c('div',[_c('div',{staticClass:"row"},[_c('div',{staticClass:"col-sm-6 align-bottom"},[_c('div',{staticStyle:{"padding":"20px 0px 6px 6px"}},[_c('span',[_vm._v("Results "),_c('b',{staticStyle:{"color":"grey"}},[_vm._v(_vm._s(Math.min(_vm.searchResult.total_results, _vm.currentPage * 10 + 1))+" - "+_vm._s(Math.min(_vm.searchResult.total_results, (_vm.currentPage + 1) * 10)))]),_vm._v(", total results "),_c('b',{staticStyle:{"color":"grey"}},[_vm._v(_vm._s(_vm.searchResult.total_results))])])])]),_c('div',{staticClass:"col-sm-6"},[_c('div',{staticStyle:{"padding":"20px 0px 6px 6px","float":"right"}},[_c('DownloadData',{attrs:{"fileName":"searchResults","downloadTitle":"Download search results","fetchData":_vm.downloadResults}})],1)])])]):_vm._e(),_c('div',[(_vm.searchResult)?_c('ListGroup',{attrs:{"searchText":_vm.searchText,"filters":_vm.searchInput.filters}}):_vm._e()],1),(_vm.isActiveSearch && !_vm.searching && _vm.searchResult)?_c('div',[_c('div',{staticClass:"row"},[_c('div',{staticClass:"col-sm-12"},[_c('div',{staticStyle:{"float":"right"}},[(_vm.pageCount > 0)?_c('Pagination',{attrs:{"initialPage":_vm.currentPage,"pageCount":_vm.pageCount,"pagesOutsideElipses":1,"pagesBetweenElipses":2},on:{"onPageChange":_vm.handlePageChange}}):_vm._e()],1)])])]):_vm._e(),(!_vm.isActiveSearch)?_c('div',{staticClass:"row"},[_c('div',{staticClass:"col-md-6"},[_c('OtherResources')],1),_c('br')]):_vm._e()],1)
}
var staticRenderFns = []

export { render, staticRenderFns }